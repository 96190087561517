import React from 'react';
import { gA } from "gulp-app-mui";
import _tl from "./stringhe";
// material-ui
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircle from '@material-ui/icons/AccountCircle';


const styles = theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: gA.drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    top : 0,
    bottom : 'auto',
    marginLeft: gA.drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${gA.drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: gA.drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  sectionDesktop: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
});


class AppMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { onSelect : props.onSelect, mobileOpen : false, open1 : false, open2 : false, open3 : false, anchorEl : null };

    this.handleLogout = this.handleLogout.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  selectMenu(key) {
    this.state.onSelect(key);
    if (this.state.mobileOpen)
      this.setState({mobileOpen : false});
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleClose() {
    this.setState({anchorEl : null});
  }



  funzioniSupervisore() {
    const { classes } = this.props;

    let voci = [],menu = [];
    if (gA.parametri.livello > 9) {
      voci.push(<ListItem button key="2.1" className={classes.nested} onClick={this.selectMenu.bind(this,"2.1")}>
        <ListItemText>Utenti</ListItemText>
        </ListItem>);
      if (gA.parametri.livello === 10) {
        voci.push(<ListItem button key="2.2" className={classes.nested} onClick={this.selectMenu.bind(this,"2.2")}>
          <ListItemText>Gruppo Utenti</ListItemText>
          </ListItem>);
        voci.push(<Divider />);
        voci.push(<ListItem button key="2.4" className={classes.nested} onClick={this.selectMenu.bind(this,"2.4")}>
          <ListItemText>Tipo di comunicazione</ListItemText>
          </ListItem>);
      }
      menu.push(
        <div>
          <Divider/>
          <List>
            <ListItem button key="1" onClick={() => this.setState({open2 : !this.state.open2})} >
              <ListItemText primary="Amministrazione" />
              {this.state.open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {voci}
              </List>
            </Collapse>
          </List>
        </div>
      );
    }
    return menu; 
  }

  funzioniAmministratore() {
    const { classes } = this.props;

    let menu = [];
    if (gA.parametri.livello > 9) {
      menu.push(
        <div>
          <Divider/>
          <List>
            <ListItem button key="1" className={classes.nested} onClick={this.selectMenu.bind(this,"1")}>
              <ListItemText>{_tl[gA.lingua].reports}</ListItemText>
            </ListItem>
          </List>
        </div>
      );
    }
    return menu; 
}

  handleLogout() {
    this.selectMenu("10");
    this.setState({anchorEl : null});
  }

  handleClick(e) {
    this.setState({anchorEl : e.target});
  }


  render() {

    const { classes,theme } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem button key="1" onClick={() => this.setState({open1 : !this.state.open1})} >
            <ListItemText primary="Operazioni" />
            {this.state.open1 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.open1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              { gA.parametri.livello > 1 && <ListItem button key="1.1" className={classes.nested} onClick={this.selectMenu.bind(this,"1.1")}>
                <ListItemText>Reports</ListItemText>
              </ListItem>}
              <ListItem button key="1.2" className={classes.nested} onClick={this.selectMenu.bind(this,"1.2")}>
                <ListItemText>Disponibilità</ListItemText>
              </ListItem>
            </List>
          </Collapse>
        </List>
        {this.funzioniSupervisore()}
        {this.funzioniAmministratore()}
      </div>
    );

    return (
      <div>        
        <AppBar 
          position="fixed"          
          className={classes.appBar}
        >         
            <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="go Home"
              onClick={this.selectMenu.bind(this,"0")}
            >
              <HomeIcon />
            </IconButton>
            <Typography variant="h5" color="inherit" noWrap>
            {_tl[gA.lingua].nomeApplicazione}
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                  aria-owns='material-appbar'
                  aria-haspopup="true"
                  onClick={this.handleClick}
                  color="inherit"
                >
                  <AccountCircle />
              </IconButton>
              <Menu id="user-menu" variant="menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleClose.bind(this)}>
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
              </Menu>             
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden mdUp implementation="js">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="js">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>        
    );
  }
}

export default withStyles(styles, { withTheme: true })(AppMenu);

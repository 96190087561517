import React, { Component } from 'react';
import './App.css';
import AppMenu from './menu';
import { gA, FormStd, TParametri, procUtils, _t, TAutenticazione } from "gulp-app-mui";
import _tl from "./stringhe";
import Dashboard from "./dashboard";
import $ from "jquery";
import Reports from "./reports"
import Disponibilita from './disponibilita';

// material ui
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(6))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "90%",
  },
  bottone: {
    justify:'right'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  toolbar: theme.mixins.toolbar,

  
  content: {
    [theme.breakpoints.up("md")]: {
      marginLeft: gA.drawerWidth,
      width: `calc(100% - ${gA.drawerWidth}px)`
    }
  },
  
  root: {
    display: '',
  },
});


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autorizzato:false, // porre a false quando completata
      form:"-1"
    }
    this.username = procUtils.urlParam('u');
    this.password = procUtils.urlParam('p');
    this.centro = procUtils.urlParam('c');
    this.salva = false;
    // cerca di leggere i valori dal localstore se esistono
    if (window.localStorage.getItem('gaub_salva') == 'yes' ) {
      this.username = window.localStorage.getItem('gaub_user');
      this.password = window.localStorage.getItem('gaub_pass');
      this.centro = window.localStorage.getItem('gaub_centro');
    }
    this.daUrl = false; // imposta che il codice del centro non è stato inviato da url
    if (this.state.autorizzato === false) {
      this.centro = window.location.hash.substring(1);
      if (this.centro.length == 0) {
        // tenta il recupero dai cookies
        //this.centro = cookie.load("centro");
        this.centro = window.localStorage.getItem('gaub_centro');
        if (this.centro == undefined)
          this.centro = "";
        }
      else
      {
        const expires = new Date()
        expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14)
             // salva il cookie
        //cookie.save("centro",this.centro, { path: '/', expires, maxAge: 1000 });
        window.localStorage.setItem('gaub_centro',this.centro);
      }
    }
    this._login = this._login.bind(this);
    this._logout = this._logout.bind(this);
    this.SelezionaMenu = this.SelezionaMenu.bind(this);
    this.rispostaLogin = this.rispostaLogin.bind(this);
    this.passwordErrata = false;
    gA.width = window.innerWidth;
    gA.parametri = new TParametri();
    gA.parametri.Add("IVASTAND","16");
    gA.parametri.Add("id_anag",0);
    gA.globali = { giornoSelezionato : 0, attivitaSelezionata : 0, percorso:'' };
    if (gA.baseUrlForms === "") 
      gA.baseUrlForms = gA.baseUrlServer;
    this.autenticazione = new TAutenticazione;
    if (this.username !== '' && this.centro !== '') {
      // legge dal server i parametri per il centro che si desidera usare
      gA.opzCentro = this.syncRequest("getserver.php", { n : this.centro });
      if (gA.opzCentro.risposta === 'OK' && gA.opzCentro.opzioni.attivo == 1) {
        // richiesta autenticazione sul server
        //console.log(gA.opzCentro);
        if (gA.opzCentro.url.indexOf("http.//") > -1) {
          gA.remotePath = gA.opzCentro.url.replace("http.//","http://");
        }
        else
          gA.remotePath = gA.opzCentro.url;
        this.autenticazione.Autenticazione(this.rispostaLogin,this.username,this.password);
      } 
    }
  }

  rispostaLogin(risultato) {
    if (risultato.autorizzato) {
      this.passwordErrata = false;
      gA.parametri.codice_utente = risultato.codice_utente;
      gA.parametri.supervisore = risultato.utente_supervisore;
      gA.parametri.livello = risultato.livello;
      gA.parametri.Set("id_anag",risultato.id_anag);
      gA.parametri.dataUltimoAccesso = risultato.dataUltimoAccesso;
      gA.urlCentro = gA.dirUrl;
      this.msgNoLogin = "";
      this.setState({ autorizzato: true });
      // memorizza i valori se necessario
      if (this.salva) {
        window.localStorage.setItem('gaub_salva','yes');
        window.localStorage.setItem('gaub_centro',this.centro);
        window.localStorage.setItem('gaub_user',this.username);
        window.localStorage.setItem('gaub_pass',this.password);
      }
    } else {
        this.passwordErrata = true;
        this.msgNoLogin = _t[gA.lingua].passwordErrata;
        this.setState({ autorizzato: false });
    }
 }

  syncRequest(comando, params) {
    if (params == null) params = {};
    params.token = (+new Date).toString(36).slice(-5);
    let rs = {};
    rs.risultato = "NO";
    $.ajax({
        type : 'GET',
        url : gA.baseUrlGaub+'/'+comando,
        async: false,
        crossOrigin: false,
        dataType: 'json',
        success : function(result){
                rs = result;
            },
        data: params,
        error: function (x, y, z) {
            alert(x.responseText + '\n' + y + '\n' + z);
        }
    });
    if (rs.risultato == 'NO') {
        alert("Errore: "+rs.errore);
    }
    return rs;
  }

  _login(e) {
    e.preventDefault();
    if (this.centro != "") {
      // legge dal server i parametri per il centro che si desidera usare
      gA.opzCentro = this.syncRequest("getserver.php", { n : this.centro });
    } else {
      this.msgNoLogin = _t[gA.lingua].passwordErrata;
      this.setState({ autorizzato: false });
      return;
    }
    if (gA.opzCentro.risposta === 'OK' && gA.opzCentro.opzioni.attivo == 1) {
      // richiesta autenticazione sul server
      //console.log(gA.opzCentro);
      if (gA.opzCentro.url.indexOf("http.//") > -1) {
        gA.remotePath = gA.opzCentro.url.replace("http.//","http://");
      }
      else
        gA.remotePath = gA.opzCentro.url;
      this.autenticazione.Autenticazione(this.rispostaLogin,this.username,this.password);
    } else {
      this.msgNoLogin = gA.opzCentro.msg;
      this.centro = "";
      this.setState({ autorizzato: false });
    }
  }

  onChangeUsername(e) {
    this.username = e.target.value;
  }

  onChangeNomecentro(e) {
    this.centro = e.target.value;
  }

  onChangeSalva(e) {
    this.salva = e.target.checked;
  }

  clickMenu(key) {
    this.SelezionaMenu(key,null);
  }

  onChangePassword(e) {
    this.password = e.target.value;
  }

  _logout() {
      this.state.autorizzato = false;
      this.username = "";
      this.pasword = "";
      this.setState({ autorizzato: false, form: "-1" });
      // cancella lo storage
      window.localStorage.removeItem('gaub_salva');
      window.localStorage.removeItem('gaub_user');
      window.localStorage.removeItem('gaub_pass');
      window.localStorage.removeItem('gaub_centro');
  }

  SelezionaMenu(key) {
    switch(key) {
      case "-1":
        break; // non fa nulla
      case "0":
        this.props.history.push(gA.urlCentro+"/");
        break; 
      case "1.1":
        this.props.history.push(gA.urlCentro+"/reports");
        break;
      case "1.2":
        this.props.history.push(gA.urlCentro+"/disponibilita");
        break;
      case "1.3":
        this.props.history.push(gA.urlCentro+"/test");
        break;
      case "2.1":
        this.props.history.push(gA.urlCentro+"/utenti");
        break;
      case "10":
        this._logout();
        break;
      default:
        this.setState({form: "-1"});
    }
    this.setState({form:key});
  }  


  render() {
    const { classes } = this.props;

    if (this.state.autorizzato) {
      return (
        <div className={classes.root}>
          <CssBaseline />
          <AppMenu onSelect={this.SelezionaMenu}/>
          <div className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              <Route exact path={gA.urlCentro+"/"} render={(props) => (<Dashboard {...props} clickMenu={this.clickMenu.bind(this)}/>)}/>
              <Route exact path={gA.urlCentro+"/index.html"} render={(props) => (<Dashboard {...props} clickMenu={this.clickMenu.bind(this)}/>)}/>
              <Route path={gA.urlCentro+"/utenti"} render={() => (<FormStd key="utenti" nomeForm="utenti"  parametri={gA.parametri.livello === 10 ? "S = 1" : ""}/>)}/>
              <Route path={gA.urlCentro+"/reports"} render={(props) => (<Reports {...props} clickMenu={this.clickMenu.bind(this)}/>)}/>
              <Route path={gA.urlCentro+"/disponibilita"} render={(props) => (<Disponibilita {...props} clickMenu={this.clickMenu.bind(this)}/>)}/>
            </Switch>
          </div>
        </div>
        );
    } else {
      let avviso = [];
      if (this.passwordErrata) {
          avviso = <FormLabel error className={classes.textField}>{_t[gA.lingua].passwordErrata}</FormLabel>;
      }
      return (
        <div className={classes.main}>
          <CssBaseline/>
          <Paper className={classes.paper}>
            <img alt="logo" width="300px" src={process.env.PUBLIC_URL+"/logo.png"}/>
            <Typography variant="h5">{_tl[gA.lingua].nomeApplicazione}</Typography>
            <Typography variant="h6">{_tl[gA.lingua].sottotitolo}</Typography>
          <div className="AppLogin">
            <form onSubmit={this._login}>
            <Grid container>
            <Grid item xs={12}>
            {this.centro == "" && 
                          <TextField
                          id="centro"
                          required
                          label={_tl[gA.lingua].nomecentro}
                          margin="normal"
                          className={classes.textField}
                          onChange={this.onChangeNomecentro.bind(this)}
                        />}
            <TextField
              id="username"
              required
              label={_t[gA.lingua].username}
              margin="normal"
              className={classes.textField}
              onChange={this.onChangeUsername.bind(this)}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
              id="password"
              required
              label={_t[gA.lingua].password}
              margin="normal"
              className={classes.textField}
              type="password"
              autoComplete="current-password"
              onChange={this.onChangePassword.bind(this)}
            />
            </Grid>
            <Grid item xs={12}>
            {avviso}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel className={classes.textField} variant="caption"
                  control={
                      <Checkbox
                      id='salva' 
                      name='salva'
                      onChange={this.onChangeSalva.bind(this)}
                      />
                  }
                  classes={{ label: classes.label2 }}
                  label={_t[gA.lingua].salvaPassword}/>
            </Grid>
            <Grid container justify="flex-end">
            <Button type="submit" className={classes.bottone} name="Invia">{_t[gA.lingua].entra}</Button>
            </Grid>
            </Grid>
            </form>
          </div>
          </Paper>
        </div>
      )
    }
  }
}

export default withStyles(styles)(withRouter(App));
import React, { Component } from "react";
import { gA, DBWrapper } from "gulp-app-mui";
import _tl from "./stringhe";
// material ui
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '10px',
      
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    bottone: {
        width: "100%",
        marginTop: theme.spacing(3)
    }

  });

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.oggetti = props.refOggetti;
        this.datiAmministratore = <CircularProgress color="secondary"/>;
        let db = new DBWrapper();
        this.rispostaQuery = this.rispostaQuery.bind(this);
        db.rawQuery(this.rispostaQuery,"gUtente",{ id : gA.parametri.codice_utente });
        this.clickMenu = props.clickMenu;
    }

    rispostaQuery(risultato) {
        if (risultato.error != null)
            alert(risultato.error);
        else {
            if (risultato.length > 0) {
                this.datiAmministratore = (
                    <FormLabel>Bentornato: {risultato[0].NOMINATIVO}</FormLabel>
                );
                this.forceUpdate();
            }
        }
    }

    render() {
        const { classes } = this.props;

        let Bottoni = [];
        if (gA.opzCentro.opzioni.cassa === "1") {
            Bottoni.push(<Button key="Cassa" variant="contained" className={classes.bottone} color="primary" size="medium" >Cassa</Button>)
        }
        if (gA.opzCentro.opzioni.report === "1" && gA.parametri.livello > 1) {
            Bottoni.push(<Button key="Report" variant="contained" className={classes.bottone} color="primary" size="medium" onClick={() => (this.clickMenu("1.1"))}>{_tl[gA.lingua].reports}</Button>)
        }
        if (gA.parametri.livello > 0) {
            Bottoni.push(<Button key="Disponibilita" variant="contained" className={classes.bottone} color="primary" size="medium" onClick={() => (this.clickMenu("1.2"))}>{_tl[gA.lingua].disponibilita}</Button>)
        }
        return (
            <Grid container className={classes.container} spacing={8}>
                <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                        {this.datiAmministratore}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" component="h3">Seleziona una opzione</Typography>
                        {Bottoni}
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(Dashboard);
const _tl = {
    it : {
        nomeApplicazione: 'Gaub App',
        sottotitolo: 'accesso alla tua attivita online',
        operazioni : 'Operazioni',
        percorsoErrato : 'Errore: percorso errato, impostare correttamente l\'Url',
        reports : "Reports",
        listaMagazzini : "Magazzini",
        nodata: 'Non esistono elementi da visualizzare',
        esportacsv: 'Esporta in CSV',
        disponibilita: "Disponibilità",
        nomecentro: 'sigla Azienda'
    },
    en : {
        nomeApplicazione: 'Gaub App',
        sottotitolo: 'login in your activity online',
        operazioni : 'Operations',
        percorsoErrato : 'Error: wrong path Url',
        reports : "Reports",
        listaMagazzini : "Stores",
        nodata: 'There is no data to display',
        esportacsv: 'Export in CSV',
        disponibilita: 'Disponibility',
        nomecentro: 'Company Code'
    }
}

export default _tl;
import React, { Component } from "react";
import { gA, procUtils,DBWrapper, _t } from "gulp-app-mui";
import _tl from "./stringhe";
import $ from "jquery";
import moment from "moment";
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
import { Document, Page } from 'react-pdf';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import "moment/locale/it";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import lightBlue from "@material-ui/core/colors/lightBlue";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'; 

import { green, purple } from '@material-ui/core/colors';

import { MuiPickersUtilsProvider,DatePicker } from '@material-ui/pickers';


// pick a date util library
import MomentUtils from '@date-io/moment';
import { brown } from "@material-ui/core/colors";

const maskDate = "YYYY-MM-DD"
const maskDateTime = "YYYY-MM-DDTHH:mm:ss"


const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '10px',     
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    centrato: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1)
    },
    centrato2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1)
    },
    lista: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    },
    bottone: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500]
    },
    textRight: {
        textAlign: "right"
    }

  });

  const materialTheme = (theme) => createMuiTheme({
    palette: {
        type: theme.palette.type,
        primary: lightBlue
      },
  });  

class ReportPure extends Component {
    constructor(props) {
        super(props);
        this.elabora = props.elabora;
        this.dettagliato = props.dettagliato;
        this.dataReport = props.data;
        this.getElenco = props.elenco;
        this.db = new DBWrapper();
        this.state = { dati: null };
    }

    risultato(r) {
        // risultato query report
        this.setState( {dati : r});
    }

    toCurrency(numberString) {
        let number = parseFloat(numberString);
        return number.toLocaleString('it-IT',{minimumFractionDigits: 2,style: 'currency', currency: 'EUR'});
    }

    render() {
        const { classes } = this.props;

        const options = {
			exportCSVText: _tl[gA.lingua].esportacsv,
			noDataText: _tl[gA.lingua].nodata,
			clearSearch: true,
        }
        // calcola somma dei campi
        let tContanti = 0;
        let tCarte = 0;
        let tAssegni = 0;
        let tTotale = 0;
        if (this.state.dati != null) {
            this.state.dati.map(e => (
                tContanti += parseFloat(e.CONTANTI),
                tCarte += parseFloat(e.CARTE),
                tAssegni += parseFloat(e.ASSEGNO),
                tTotale += parseFloat(e.CONTANTI)+parseFloat(e.CARTE)+parseFloat(e.ASSEGNO)
            ));
        }
        let colonne = [];
        if (this.dettaglioProdotti) {

        }
        else
        {
            colonne = [{
                Header: 'Dettaglio',
                accessor: 'ID_MAGA',
                Cell: props => <Button variant="contained" color="primary" classes={{root: classes.bottone}} onClick={ () => this.props.showReport(this.dataReport(),props.value)}>Dettaglio</Button>,
                width: 130
              },{
                Header: 'Data',
                accessor: 'DATA' 
              }, {
                Header: 'Magazzino',
                accessor: 'MAGAZZINO',
                Footer: (
                    <span>Totali:</span>
                )
              }, {
                Header: 'Contanti',
                accessor: 'CONTANTI',
                Footer: (
                    <div className={classes.textRight}>
                      {this.toCurrency(tContanti)}
                    </div>
                  ),
                Cell: props => <div className={classes.textRight}> {this.toCurrency(props.value)} </div>
              }, {
                Header: 'Carte',
                accessor: 'CARTE',
                Footer: (
                    <div className={classes.textRight}>
                      {this.toCurrency(tCarte)}
                    </div>
                  ),
                Cell: props => <div className={classes.textRight}> {this.toCurrency(props.value)} </div>
              }, {
                Header: 'Assegni',
                accessor: 'ASSEGNO',
                Footer: (
                    <div className={classes.textRight}>
                      {this.toCurrency(tAssegni)}
                    </div>
                  ),
                Cell: props => <div className={classes.textRight}> {this.toCurrency(props.value)} </div>
              }, {
                Header: 'Totale',
                accessor: 'TOTALE',
                Footer: (
                    <div className={classes.textRight}>
                      {this.toCurrency(tTotale)}
                    </div>
                  ),
                Cell: props => <div className={classes.textRight}> {this.toCurrency(props.value)} </div>
              }];
        }
        if (this.elabora()) {
            // ricava i magazzini attivi
            let listaMagazzini = "";
            let elenco = this.getElenco();
            this.setState({dati: null});
            elenco.map(function(e) { 
                if (e.checked) { 
                    if (listaMagazzini.length > 0)
                      listaMagazzini += ',';
                    listaMagazzini += e.ID 
                } 
            });
            if (listaMagazzini.length == 0) {
                procUtils.avviso("E' necessario selezionare almeno un magazzino");
            } else {
                listaMagazzini = " and (TDOCUMENTI.COD_MAG in (" + listaMagazzini +')) ';
                this.db.rawQuery(this.risultato.bind(this),'gResoconto',{ data: this.dataReport(),_f : listaMagazzini });
            }
        }
        return (
            <Paper className={classes.paper}>
                <Typography variant="h6" component="h3">Report</Typography>
                { this.state.dati != null ?
                <ReactTable data={this.state.dati} columns={colonne} showPagination={false} minRows={1} noDataText={_tl[gA.lingua].nodata} className="-striped -highlight" />
                : <CircularProgress color="secondary"/> }
            </Paper>
        );
    }
}

class ElencoMagazziniPure extends Component {
    constructor(props) {
        super(props);
        this.db = new DBWrapper();
        this.elenco = [];
        this.setElenco = props.setElenco;
        this.getElenco();
    }

    risultato(r) {
        this.elenco = r;
        this.elenco.map(e => e.checked=true);
        this.setElenco(this.elenco);
        this.forceUpdate();
    }

    getElenco() {
        // richiesa ajax al server per richiedere l'elenco dei magazzini
        this.db.rawQuery(this.risultato.bind(this),'gMagazzini',null);
    }

    clickItem(i) {
        this.elenco[i].checked = this.elenco[i].checked ? false : true;
        this.setElenco(this.elenco);
        this.forceUpdate();
    }

    render() {
        const { classes } = this.props;
        var elenco = this.elenco;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography variant="h6" component="h3">{_tl[gA.lingua].listaMagazzini}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <List className={classes.lista}>
                    { elenco.length > 0 ?
                        elenco.map((e,index) => 
                        <ListItem key={e.ID}>
                            <ListItemIcon>
                                <Checkbox id={e.ID} checked={e.checked} onChange={this.clickItem.bind(this,index)}/>
                            </ListItemIcon>
                            <ListItemText id={e.ID} primary={e.DESCR} />
                        </ListItem>
                    ) :
                    <CircularProgress color="secondary"/> }
                </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}


class Pdf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
            parametri : props.parametri
        };
    }
    
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }
    
    pageNext() {
        if (this.state.pageNumber < this.state.numPages) 
          this.setState({pageNumber : this.state.pageNumber+1});
    }
    
    pagePrev() {
        if (this.state.pageNumber > 1) 
          this.setState({pageNumber : this.state.pageNumber-1});
    }

    render() {
        const { pageNumber, numPages } = this.state;
        return (
            <div refs='show'>
            <Button variant="contained" color="secondary" size="medium" onClick={this.pagePrev.bind(this)}>
                &lt;</Button>
                &nbsp;Pagina {pageNumber} di {numPages}&nbsp;
            <Button variant="contained" color="secondary" size="medium"  onClick={this.pageNext.bind(this)}>
                &gt;</Button><br/>
            <Document
              file={gA.baseUrlServer+'/api/report.php?'+this.state.parametri}
              onLoadSuccess={this.onDocumentLoadSuccess}
              loading="Attendere..."
              onLoadError={(error) => alert('Errore: '+error.message)}
            >
              <Page pageNumber={pageNumber} width={gA.width} onRenderSuccess={() => window.scrollTo(0,400)}/>
            </Document>
            </div>    
        )
    }


}

class Reports extends Component {
    constructor(props) {
        super(props);
        this.dataReport = moment().hour(0).minute(0).seconds(0);
        this.dettaglioProdotti = false;
        if (gA.history == undefined) gA.history = this.props.history;
        this.elabora = false;
        this.db = new DBWrapper();
        this.state = { 
            focused : false,
            parametri: '',
            showPdf : false,
            primoAccesso : true
        };
    }


    clickDettaglio () {
        this.dettaglioProdotti = !this.dettaglioProdotti;
        this.forceUpdate();
    }

    elaboraReport() {
        this.elabora = true;
        this.setState( {showPdf : false});
    }

    getElabora() {
        if (this.elabora) {
            this.elabora = false;
            return true;
        }
        return this.elabora;
    }

    setElenco(e) {
        this.elenco = e;
        if (this.state.primoAccesso) {
            this.elaboraReport();
            this.setState({ primoAccesso : false });
        }
    }

    getElenco() {
        return this.elenco;
    }

    dataChange (e) {
        this.dataReport = e;
        this.elaboraReport();
    }

    getData() {
        return this.dataReport.format(this.db.maskDate);
    }

    showReportSafari(data,id_maga) {
        window.open(gA.baseUrlServer+'/api/report.php?r='+gA.remotePath.replace("http://","http.//")+'&token='+gA.token+'&data='+data+'&rp=dettaglio&maga='+id_maga,'_blank','');
    }

    visualizza(risposta) {
        //Create a Blob from the PDF Stream
        const file = new Blob(
            [risposta], 
            {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
    }

    _showReport(data,id_maga) {
        var params = {
            r: gA.remotePath.replace("http://","http.//"),
            token: gA.token,
            data: data,
            rp: 'dettaglio',
            maga: id_maga
        };
        $.ajax({
            type : 'GET',
            url : gA.baseUrlServer+'/api/report.php',
            async: true,
            crossOrigin: true,
            success : this.visualizza,
            data: params,
            error: function (x, y, z) {
                if (x.status === 404) {
                    alert(_t[gA.lingua].sessioneScaduta);
                    window.location = gA.dirUrl;
                  }
                  else {
                    alert(x.responseText + '\n' + y + '\n' + z);
                  }
            }
        });
    }

    showReport(data,id_maga) {

        if (navigator.userAgent.indexOf("Chrome") != -1) {
            this.showReportNormale(data,id_maga);
        } else
        if (navigator.userAgent.indexOf("Safari") != -1) {
            this.showReportSafari(data,id_maga);
        } else {
            this.showReportNormale(data,id_maga);        
        }
    }
    

    showReportNormale(data,id_maga) {
        fetch(gA.baseUrlServer+'/api/report.php?r='+gA.remotePath.replace("http://","http.//")+'&token='+gA.token+'&data='+data+'&rp=dettaglio&maga='+id_maga)
        .then(response => response.arrayBuffer())
        .then(risposta => {
            const file = new Blob(
                [risposta], 
                {type: 'application/pdf'});
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL,'_blank');
        })
        .catch(err => console.error(err)); // Never forget the final catch!
    }

    _showReport(data,id_maga) {
        if (!this.state.showPdf) {
            this.setState({
                parametri: 'r='+gA.remotePath.replace("http://","http.//")+'&token='+gA.token+'&data='+data+'&rp=dettaglio&maga='+id_maga, 
                showPdf : true
            });
        } else {
            this.setState({
                parametri: 'r='+gA.remotePath.replace("http://","http.//")+'&token='+gA.token+'&data='+data+'&rp=dettaglio&maga='+id_maga, 
                showPdf:false
            });
            setTimeout(()=>{ this.setState({showPdf:true})},200);
        }
    }

    render() {
        const { classes } = this.props;

          return (
            <ThemeProvider theme={materialTheme}>
            <Grid container className={classes.container} spacing={2}>
                <Grid item container xs={12} sm={12}>
                    <Grid  className={classes.centrato2} item sm={4} xs={12}>
                        <FormLabel>Selezionare Data</FormLabel><br/>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker value={this.dataReport} locale={gA.lingua} todayLabel={_t[gA.lingua].oggi} 
                                cancelLabel={_t[gA.lingua].annulla} clearLabel={_t[gA.lingua].cancella}
                                onChange={this.dataChange.bind(this)} format={gA.formatData}>
                                </DatePicker>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid  className={classes.centrato} item sm={4} xs={12}>
                        <ElencoMagazzini setElenco={this.setElenco.bind(this)} />
                    </Grid>
                    <Grid  className={classes.centrato} item sm={4} xs={12}>
                      <Button variant="contained" color="primary" size="medium" onClick={this.elaboraReport.bind(this)}>Elabora</Button><br/>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Report elabora={this.getElabora.bind(this)} elenco={this.getElenco.bind(this)} dettagliato={this.dettaglioProdotti} 
                        showReport={this.showReport.bind(this)} data={this.getData.bind(this)}/>
                </Grid>
            </Grid>
            {this.state.showPdf && <Pdf parametri={this.state.parametri}/>}
            </ThemeProvider>
          );
    }
}

const Report = withStyles(styles)(ReportPure)
const ElencoMagazzini = withStyles(styles)(ElencoMagazziniPure)

export default withStyles(styles)(Reports);
var gA = {
    DEBUG : true,
    //baseUrlServer : 'http://localhost:61011/',
    //baseUrlServer : 'http://192.168.1.4:8080',
    baseUrlServer : 'https://www.gaub.it/app',
    baseUrlForms : '',
    baseUrlGaub : 'https://www.gaub.it/gaubserver',
    //dirUrl : '',  // da impostare con il percorso del server
    dirUrl: '/app',
    pathForms : 'forms.php?f=',
    pathDatabase : 'api/dbdriver.php',
    pathAuth : 'api/autenticazione.php',
    remotePath : '',
    lingua : 'it',
    token : '',
    keyA : '',
    FATTORE_X : 8,
    FATTORE_X_DETTAGLIO: 20,
    MAX_WIDTH : 780,
    BORDER_X: 10,
    mPrecodice : 1,
    mModuli: "ABCDEFG",
    userEvents : null,  // deve contenere una function di nome "funzione" con i seguenti parametri: string nomefunzione, (generico), OggettiForm
    valuta : {
        sigla : 'E',
        mask : '###,###,##0.00'
    },
    formatData : 'DD/MM/YYYY',
    formatTempo : 'HH:mm',
    parametri : null,
    opzCentro : null,
    codiceCentro : '',
    urlCentro :'' ,
    drawerWidth : 240
};

export default gA;
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'typeface-roboto';
import './index.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; 
import * as serviceWorker from './serviceWorker';
import gulpApplication from "./gulpApplication";
import { gA } from "gulp-app-mui";

import {
  BrowserRouter as Router
} from "react-router-dom";


Object.assign(gA,gulpApplication);


const theme = createMuiTheme({
  palette: {
    type: "dark"
  }
});

ReactDOM.render(
  <Router>
     <MuiThemeProvider theme = { theme }>
       <App/>
     </MuiThemeProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from "react";
import { gA, procUtils, DBWrapper } from "gulp-app-mui";
import _tl from "./stringhe";
import moment from "moment";
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'

// material ui
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';



const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '10px',
      
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    centrato: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3)
    },
    textCenter: {
        textAlign: "center"
    }
  });



class ElencoPure extends Component {
    constructor(props) {
        super(props);
        this.elabora = props.elabora;
        this.codice = props.codice;
        this.db = new DBWrapper();
        this.state = { dati: [], prodotto : '' };
    }

    risultato(r) {
        // risultato query report
        if (r.risultato === 'ok') {
          console.log(r.dati);
          this.setState( { dati : r.dati, prodotto : r.nome });
        }
        else {
          procUtils.avviso(r.nome);
          this.setState( { dati : [], prodotto : '' });
        }
    }

    render() {
        const { classes } = this.props;
        const options = {
			exportCSVText: _tl[gA.lingua].esportacsv,
			noDataText: _tl[gA.lingua].nodata,
			clearSearch: true,
        }
        let colonne = [{Header: "articolo"},{Header: "taglie"}];
        if (this.state.dati.length > 0) {
            colonne = [];
            Object.keys(this.state.dati[0]).map( e => (
                colonne.push( {Header:e, accessor: e, Cell: props => <div className={classes.textCenter}>{props.value}</div>} )
            ))
        }
        console.log(colonne);
        if (this.elabora()) {
            procUtils.asyncRequest(this.risultato.bind(this),'api/disponibilita.php',{ c : this.codice });
        }
        return (
            <Paper className={classes.paper}>
                <Typography variant="h6" component="h3">Disponibilità: {this.state.prodotto}</Typography>
                <ReactTable data={this.state.dati} columns={colonne} showPagination={false} minRows={1} noDataText={_tl[gA.lingua].nodata} className="-striped -highlight" />
            </Paper>
        );
    }
}


class Disponibilita extends Component {
    constructor(props) {
        super(props);
        this.dataReport = moment().hour(0).minute(0).seconds(0);
        this.dettaglioProdotti = false;
        if (gA.history == undefined) gA.history = this.props.history;
        this.elabora = false;
        this.db = new DBWrapper();
        this.state = { value : ""};
    }


    clickDettaglio () {
        this.dettaglioProdotti = !this.dettaglioProdotti;
        this.forceUpdate();
    }

    elaboraReport() {
        if ( this.state.value.length > 0) {
            this.elabora = true;
            this.forceUpdate();
        } else
            procUtils.avviso("E' necessario inserire un codice prodotto.");
    }

    getElabora() {
        if (this.elabora) {
            this.elabora = false;
            return true;
        }
        return this.elabora;
    }

    codiceChange(e) {
        this.setState( { value : e.target.value });
    }

    getCodice() {
        return this.state.value;
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.container} spacing={3}>
                <Grid item container>
                    <Grid item className={classes.centrato} sm={6} xs={12}>
                        <TextField
                            id="codice"
                            required
                            label="Codice prodotto"
                            margin="normal"
                            className={classes.textField}
                            onChange={this.codiceChange.bind(this)}
                            />
                        <Button variant="contained" color="primary" size="medium" onClick={this.elaboraReport.bind(this)}>Elabora</Button>
                        <br/>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Elenco elabora={this.getElabora.bind(this)} codice={this.getCodice.bind(this)} />
                </Grid>
            </Grid>
        );
    }
}

const Elenco = withStyles(styles)(ElencoPure)

export default withStyles(styles)(Disponibilita);